import { ReactNode } from "react";
import _ from "lodash";

import useSnackbar from "@hooks/use-snackbar";
import useToggle from "@hooks/use-toggle";
import analytics from "@lib/analytics";
import { ArtifactType } from "@lib/shared-types";

import ConfirmRescheduleCancelAppointmentModal from "@components/AppointmentTypeForm/ConfirmRescheduleCancelAppointmentModal";

import CancelModal from "./CancelModal";

type Props = {
  trigger?: ReactNode;
  show?: boolean;
  toggleShow?: (val: boolean) => void;
  onCancel: (val: any) => void;
  onRecurringCancel?: (val: void) => void;
  artifactType: ArtifactType;
  showOutcomeModal?: boolean;
  reasonRequired?: boolean;
};

const CancelAppointmentModal: React.FC<Props> = ({
  trigger,
  show,
  toggleShow,
  onCancel,
  onRecurringCancel,
  artifactType,
  showOutcomeModal,
  reasonRequired,
}) => {
  const snackbar = useSnackbar();

  const {
    value: cancelAppointmentModalOpen,
    toggle: toggleCancelAppointmentModal,
  } = useToggle();

  const track = (event: string) => {
    analytics.track({
      event,
      properties: { artifactType },
    });
  };

  const handleCancelAppointment = async (reason?: string) => {
    try {
      await Promise.resolve(onCancel(reason));
      track("cancel_modal_canceled");
      snackbar.showMessage(
        `${_.capitalize(artifactType)} canceled`,
        `You've successfully canceled the ${artifactType}`,
        "",
        undefined
      );
    } catch (e) {
      track("cancel_modal_canceled_error");
      snackbar.showMessage(
        `Canceling ${artifactType} failed`,
        `There was an error canceling the ${artifactType}`,
        "",
        undefined
      );
    }
  };

  return (
    <>
      {show && (
        <CancelModal
          trigger={trigger}
          show
          toggleShow={toggleShow}
          onCancel={handleCancelAppointment}
          onRecurringCancel={onRecurringCancel}
          artifactType={artifactType}
          onAction={() => {
            if (showOutcomeModal === true) {
              toggleCancelAppointmentModal();
            } else {
              handleCancelAppointment();
            }
          }}
        />
      )}

      {cancelAppointmentModalOpen && (
        <ConfirmRescheduleCancelAppointmentModal
          action="cancel"
          show
          toggleShow={toggleCancelAppointmentModal}
          onAction={handleCancelAppointment}
          reasonRequired={reasonRequired}
        />
      )}
    </>
  );
};

export default CancelAppointmentModal;
