import { ReactNode } from "react";
import _ from "lodash";

import useSnackbar from "@hooks/use-snackbar";
import analytics from "@lib/analytics";
import { ArtifactType } from "@lib/shared-types";

import BlockIcon from "@components/Icons/BlockIcon";
import SmallModal from "@components/Modals/SmallModal";

type Props = {
  trigger?: ReactNode;
  show?: boolean;
  toggleShow?: (val: boolean) => void;
  onCancel: (val: any) => void;
  onRecurringCancel?: (val: void) => void;
  onAction?: () => void;
  artifactType: ArtifactType;
};

const CancelModal: React.FC<Props> = ({
  trigger,
  show,
  toggleShow,
  onCancel,
  onRecurringCancel,
  onAction,
  artifactType,
}) => {
  const snackbar = useSnackbar();
  const isApptType = artifactType === "appointment";

  const onActionText = isApptType
    ? "Decline appointment"
    : `Cancel ${artifactType}`;

  const title = isApptType ? "Confirm your decline?" : `Confirm cancellation?`;

  const track = (event: string) => {
    analytics.track({
      event,
      properties: { artifactType },
    });
  };

  return (
    <SmallModal
      trigger={trigger}
      show={show}
      toggleShow={toggleShow}
      icon={BlockIcon}
      iconColor="peach"
      title={title}
      description={[
        `This will permanently cancel this ${artifactType} from everywhere.`,
        isApptType &&
          "Your linked notes can still be found under your contact's profile.",
      ]
        .filter(Boolean)
        .join(" ")}
      onAction={async () => {
        if (onAction) {
          onAction();
        } else {
          try {
            await onCancel();
            track("cancel_modal_canceled");
            snackbar.showMessage(
              `${_.capitalize(artifactType)} canceled`,
              `You've successfully canceled the ${artifactType}`,
              "",
              undefined
            );
          } catch (e) {
            track("cancel_modal_canceled_error");
            snackbar.showMessage(
              `Canceling ${artifactType} failed`,
              `There was an error canceling the ${artifactType}`,
              "",
              undefined
            );
          }
        }
      }}
      onActionText={onActionText}
      onSecondaryAction={
        onRecurringCancel
          ? async () => {
              try {
                await onRecurringCancel();
                track("calcem_modal_recurring_canceled");
                snackbar.showMessage(
                  `${_.capitalize(artifactType)}s canceled`,
                  `You've successfully canceled the ${artifactType}s`,
                  "",
                  undefined
                );
              } catch (e) {
                track("cancel_modal_recurring_canceled_error");
                snackbar.showMessage(
                  `Canceling ${artifactType}s failed`,
                  `There was an error canceling the ${artifactType}s`,
                  "",
                  undefined
                );
              }
            }
          : undefined
      }
      onSecondaryActionText={
        onRecurringCancel && `This and following ${artifactType}s`
      }
      heapModalName="cancel_modal"
    />
  );
};

export default CancelModal;
